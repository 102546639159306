import Button from './Button'
import Logo from './Logo'
import Menus from './Menus'

const pages = [
	{ id: 9234809384934, name: 'Home', url: '/' },
	{ id: 81238732874454, name: 'About us', url: '/about' },
	{ id: 348938493284, name: 'Services', url: '#' },
	{ id: 545049504509, name: 'Team', url: '#' },
]

export default function Navbar() {
	return (
		<header>
			<div className="container">
				<div className="header-con">
					<nav className="navbar navbar-expand-lg navbar-light p-0">
						<Logo />
						<button
							className="navbar-toggler p-0 collapsed"
							type="button"
							data-toggle="collapse"
							data-target="#navbarSupportedContent"
							aria-controls="navbarSupportedContent"
							aria-expanded="false"
							aria-label="Toggle navigation"
						>
							<span className="navbar-toggler-icon"></span>
							<span className="navbar-toggler-icon"></span>
							<span className="navbar-toggler-icon"></span>
						</button>
						<div
							className="collapse navbar-collapse justify-content-end"
							id="navbarSupportedContent"
						>
							<Menus pages={pages} />
							<Button variant="contact-btn" url="/contact">
								Contact us
							</Button>
						</div>
					</nav>
				</div>
			</div>
			{/* <!--navbar-end--> */}
		</header>
	)
}
