import Home from './components/pages/Home'
import Contact from './components/pages/Contact'
import About from './components/pages/About'

import { BrowserRouter, Routes, Route } from 'react-router-dom'

export default function App() {
	return (
		<BrowserRouter>
			<Routes>
				<Route exact path="/" element={<Home />} />
				<Route exact path="/about" element={<About />} />
				<Route exact path="/contact" element={<Contact />} />
			</Routes>
		</BrowserRouter>
	)
}
