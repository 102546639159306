import Button from '../Button'

export default function () {
	return (
		<section>
			<div className="form-main-con dots-left-img">
				<div className="container overlay-content">
					<div className="form-title-con text-center wow slideInLeft">
						<h5>Get in touch</h5>
						<h2>Send us a Message</h2>
					</div>
					<form
						className="form-inner-con wow slideInLeft"
						id="contactpage"
						method="POST"
					>
						<div className="row">
							<div className="col-lg-4 col-md-4 col-12">
								<input
									placeholder="Name"
									name="name"
									id="name"
									type="text"
								/>
							</div>
							<div className="col-lg-4 col-md-4 col-12">
								<input
									placeholder="Email"
									name="email"
									id="email"
									type="email"
								/>
							</div>
							<div className="col-lg-4 col-md-4 col-12">
								<input
									placeholder="Phone"
									name="phone"
									id="phone"
									type="tel"
								/>
							</div>
						</div>
						<div className="row">
							<div className="col-lg-12">
								<textarea
									className="w-100"
									placeholder="Message"
									rows="3"
									name="comments"
									id="comments"
								></textarea>
							</div>
						</div>
						<div className="row">
							<div className="col-lg-12 text-center">
								<Button
									type="submit"
									id="submit"
									variant="contact-btn"
									noMargin
								>
									Submit
								</Button>
							</div>
						</div>
					</form>
				</div>
			</div>
		</section>
	)
}
