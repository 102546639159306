const partners = [
	'assets/image/slider-item-img1.png',
	'assets/image/slider-item-img2.png',
	'assets/image/slider-item-img3.png',
	'assets/image/slider-item-img4.png',
	'assets/image/slider-item-img5.png',
	'assets/image/slider-item-img6.png',
]

export default function Partners() {
	return (
		<section>
			<div className="slider-con w-100">
				<div className="container">
					<div className="slider-heading text-center">
						<h5>Partners</h5>
						<h2>We're Working With</h2>
					</div>
					<div className="row wow fadeInUp">
						{partners.map(partner => (
							<div
								className="col-lg-2 col-md-4 col-6"
								key={Math.random()}
							>
								<div className="partner-box text-center mb-lg-0 mb-4">
									<figure className="mb-0">
										<img
											src={partner}
											alt=""
											className="img-fluid"
										/>
									</figure>
								</div>
							</div>
						))}
					</div>
				</div>
			</div>
		</section>
	)
}
