export default function WeAre({ title, content, datam }) {
	return (
		<section>
			<div className="informationmain-con dots-left-img w-100">
				<div className="container overlay-content">
					<div className="row">
						<div className="col-lg-6 col-md-6 col-sm-12 informationmain-left-con">
							<div className="informationmainleft-sec-img wow slideInLeft">
								<figure className="mb-0">
									<img
										src="assets/image/informationmain-left-sec-img.png"
										alt="informationmain-left-sec-img"
									/>
								</figure>
							</div>
						</div>
						<div className="col-lg-6 col-md-6 col-sm-12 informationmain-right-con wow slideInRight">
							<div className="informationmain-right-heading">
								<h5>Who we are</h5>
								<h2>{title}</h2>
							</div>
							<div className="informationmain-right-content">
								<p>{content}</p>

								<ul className="mb-0 list">
									{datam.map(data => (
										<li key={Math.random()}>
											<i className="fas fa-check-circle"></i>
											{data}
										</li>
									))}
								</ul>
							</div>
						</div>
					</div>
				</div>
			</div>
		</section>
	)
}
