import Button from "../Button";
import RectBox from "../RectBox";

export default function CyberSecurity() {
  return (
    <section>
      <div className="Cyber-Security-con bg-overly-img w-100">
        <div className="container overlay-content">
          <div className="row">
            <div className="col-lg-6 col-md-12 Cyber-Security-left-con wow slideInLeft">
              <div className="row Cyber-Security-left-main-con">
                <RectBox
                  image="assets/image/Cyber-Security-left-card-img1.png"
                  title="Global Projects"
                  number={3329}
                />
                <RectBox
                  image="assets/image/Cyber-Security-left-card-img2.png"
                  title="Clients Protected"
                  number={4587}
                />
              </div>
              <div className="row">
                <RectBox
                  image="assets/image/Cyber-Security-left-card-img3.png"
                  title="Service Guarantee"
                  staticText={100}
                />
                <RectBox
                  image="assets/image/Cyber-Security-left-card-img3.png"
                  title="Expert Team Members"
                  membars={29}
                />
              </div>
            </div>
            <div className="col-lg-6 col-md-12 wow slideInRight">
              <div className="Cyber-Security-right-heading-con">
                <h5>Statistics</h5>
                <h2>Our Large Achievements in Cyber Security</h2>
                <p>.</p>
                <Button variant="contact-btn" url="/about" noMargin>
                  Get started
                </Button>
              </div>
            </div>
          </div>
        </div>
      </div>
    </section>
  );
}
