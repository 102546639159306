import { Link } from 'react-router-dom'

export default function Menus({ pages, footer, listClass, additionalItems }) {
	pages = pages || []
	return (
		<ul className={`${listClass} ${footer ? 'list-unstyled mb-0' : 'navbar-nav'}`}>
			{pages.map(page => (
				<li
					key={page.id}
					className={`${
						page.type !== undefined && page.type === 'text'
							? 'weight-footer-item2-content col-lg-11 pl-0 pr-0'
							: ''
					}${footer ? '' : 'nav-item active'}`}
				>
					{page.type === 'text' ? (
						page.name
					) : (
						<Link
							className={`${footer ? '' : 'nav-link text-white p-0'}`}
							to={`${
								page.type !== undefined && page.type === 'email'
									? `mailto:${page.name}`
									: ''
							}${
								page.type !== undefined && page.type === 'number'
									? `tel:${page.number}`
									: ''
							}${page.url !== undefined ? page.url : ''}
						`}
						>
							{page.name}
						</Link>
					)}
				</li>
			))}
			{additionalItems}
		</ul>
	)
}
