export default function FooterWeight({ column, title, variants, content }) {
	variants = variants || []
	return (
		<div className={column}>
			<div className="weight-footer-item mb-lg-0 mb-md-0 mb-3">
				<div className="weight-footer-item-title">
					<h3>{title}</h3>
				</div>

				<div
					className={variants
						.map(variant => `weight-footer-item-${variant}`)
						.join(' ')}
				>
					{content}
				</div>
			</div>
		</div>
	)
}
