import Navbar from '../Navbar'

export default function Hero({ children }) {
	return (
		<div className="header-and-banner-con w-100">
			<div className="header-and-banner-inner-con overlay-content">
				<Navbar />
				{children}
			</div>
		</div>
	)
}
