import { Link } from 'react-router-dom'

export default function Button({ variant, url, id, type, noMargin, children }) {
	return url ? (
		<Link to={url} className={`${noMargin ? '' : 'my-2 my-sm-0'} ${variant}`}>
			{children}
		</Link>
	) : (
		<button
			type={type}
			id={id}
			className={`${noMargin ? '' : 'my-2 my-sm-0'} ${variant}`}
		>
			{children}
		</button>
	)
}
