export default function RectBox(props) {
	const { image, title, number, staticText, membars, variant } = props

	return (
		<div
			className={`${
				variant === 'SyberBox'
					? 'col-lg-3 col-md-6 col-sm-6 mb-lg-0 mb-sm-4 text-center'
					: 'col-lg-6 col-md-6 col-sm-6 text-center'
			}`}
		>
			<div className="Cyber-Security-left-card-con">
				<div className="Cyber-Security-left-card-img">
					<figure>
						<img src={image} alt={title} className="img-fluid" />
					</figure>
				</div>
				<div
					className={`Cyber-Security-left-card-title ${
						membars ? 'position-relative d-inline-block' : ''
					}`}
				>
					{number && <h3 className="count">{number}</h3>}
					{staticText && (
						<>
							<h3 className="count d-inline-block">{staticText}</h3>
							<span className="text-white static-txt2">%</span>
						</>
					)}
					{membars && (
						<>
							<h3 className="count">{membars}</h3>
							<span className="text-white position-absolute static-txt">
								+
							</span>
						</>
					)}

					<p className="mb-0">{title}</p>
				</div>
			</div>
		</div>
	)
}
