import Copyright from '../Copyright'
import Menus from '../Menus'
import NewsLetter from '../Newsletter'
import BrandWeight from '../BrandWeight'
import FooterWeight from '../FooterWeight'

const aboutPages = [
	{ id: 3434590495, name: 'About us', url: '/about' },
	{ id: 9328409384, name: 'Services', url: '#' },
	{ id: 3290483298, name: 'Team', url: '#' },
	{ id: 5948948565, name: 'Contact us', url: '/contact' },
]
const servicePages = [
	{
		id: 940932809384,
		name: '678 King Street Melbourne, 3000, Australia',
		type: 'text',
	},
	{ id: 238409238324, name: 'info@websecuritysolutions.co', type: 'email' },
	{ id: 343483984934, name: 'consent@websecuritysolutions.co', type: 'email' },
	{ id: 939283912938, name: '0668 492 688', number: '+0668492688', type: 'number' },
]

export default function Footer() {
	return (
		<section>
			<div className="weight-footer-main-con bg-overly-img">
				<div className="container overlay-content">
					<div className="weight-footer-item-con">
						<div className="row wow fadeInUp">
							<BrandWeight />
							<FooterWeight
								column="col-lg-2 col-md-3 col-5"
								title="About Us"
								variants={['link']}
								content={<Menus pages={aboutPages} footer />}
							/>
							<FooterWeight
								column="col-lg-3 col-md-4 col-7"
								title="Our Services"
								variants={['link', 'content']}
								content={
									<Menus
										pages={servicePages}
										footer
										listClass="social-icon-list"
										additionalItems={
											<>
												<li className="d-inline-block mb-0">
													<br />
												</li>
												<li className="d-inline-block mb-0">
													<br />
												</li>
												<li className="d-inline-block mb-0">
													<br />
												</li>
											</>
										}
									/>
								}
							/>
							<NewsLetter />
						</div>
					</div>
				</div>
				<Copyright />
			</div>
		</section>
	)
}
