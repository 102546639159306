export default function Copyright() {
	return (
		<div className="footer-con">
			<div className="container overlay-content">
				<div className="row">
					<div className="col-lg-12">
						<div className="footer-con text-center">
							<p>
								Copyright WSSC websecuritysolutions.co © 2022. All
								Rights Reserved.
							</p>
						</div>
					</div>
				</div>
			</div>
		</div>
	)
}
