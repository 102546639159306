import EffectiveItem from '../EffectiveItem'

const datam = [
	{
		id: 8349834,
		image: 'assets/image/contact-card-img1.png',
		title: 'Location',
		content: ['678 King Street, Melbourne Victoria 3000 Australia'],
	},
	{
		id: 832094823,
		image: 'assets/image/contact-card-img2.png',
		title: 'Phone',
		content: [
			<a href="tel:+0668492688">+0668 492 688</a>,
			<a href="tel:+0668492688">+0668 492 688</a>,
		],
	},
	{
		id: 985304958,
		image: 'assets/image/contact-card-img3.png',
		title: 'Location',
		content: ['info@websecuritysolutions.co', 'support@websecuritysolutions.co'],
	},
]

export default function EffectContact() {
	return (
		<section>
			<div className="Effective-con about-page-Effective-con service-Effective-con w-100 pt-0">
				<div className="container">
					<div className="row service-Effective-con-item mb-0 contact-box-card wow fadeInUp">
						{datam.map(data => (
							<EffectiveItem variant={3} datam={data} key={data.id} />
						))}
					</div>
				</div>
			</div>
		</section>
	)
}
