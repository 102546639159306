export default function BannerAbout() {
	return (
		<section className="banner-main-con about-page-main-banner-con">
			<div className="container">
				<div className="banner-con about-page-banner-con text-center">
					<div className="row wow slideInLeft">
						<div className="col-lg-12">
							<div className="about-page-banner-title">
								<h1>About Us</h1>
								<p className="mb-0">
									Cyber security solutions for everyone regardless of
									your budget !<br />
									<br />
									We believe everyone should be entitled to security
									this is why we work with you directly with small
									business's to come to a understanding. <br />
									No business should not be able to afford security,
								</p>
							</div>
						</div>
					</div>
				</div>
			</div>
		</section>
	)
}
