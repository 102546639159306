import Footer from '../sections/Footer'
import Hero from '../sections/Hero'
import GetInTouch from '../sections/GetInTouch'
import BannerContact from '../sections/BannerContact'
import EffectContact from '../sections/EffectContact'
import Map from '../sections/Map'
import { useEffect } from 'react'

export default function Contact() {
	useEffect(() => {
		document.title = 'Cyber Chip | Contact-us'
		return function () {
			window.scrollTo(0, 0)
		}
	}, [])

	return (
		<>
			<Hero>
				<BannerContact />
				<EffectContact />
			</Hero>
			<GetInTouch />
			<Map />
			<Footer />
		</>
	)
}
