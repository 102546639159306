export default function NewsLetter() {
	return (
		<>
			<div className="col-lg-3 col-md-5 col-12">
				<div className="weight-footer-item mb-0">
					<div className="weight-footer-item-title">
						<h3 className="Newsletter-title">Newsletter</h3>
					</div>
					<div className="weight-footer-item-form">
						<div className="weight-footer-item-form-input">
							<input placeholder="Enter your email" type="email" />
							<button className="border-0" value="">
								<img
									src="assets/image/submit-img.png"
									alt="submit-img"
									className="img-fluid"
								/>
							</button>
						</div>
						<div className="form-group mb-0">
							<input id="html" type="checkbox" />
							<label htmlFor="html" className="checkbox-details mb-0">
								I Agree to the Terms
							</label>
						</div>
					</div>
				</div>
			</div>
		</>
	)
}
