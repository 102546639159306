export default function CircleQuality({ parcentage, title, variant }) {
	return (
		<div className="col-lg-4 col-md-4 col-sm-4 col-12">
			<div className={`circle-wrap ${variant}`}>
				<div className="circle">
					<div className="mask full">
						<div className="fill"></div>
					</div>
					<div className="mask half">
						<div className="fill"></div>
					</div>
					<div className="inside-circle">
						<div className="service-skill-sttaf-item-con">
							<div className="service-skill-sttaf-item-title service-skill-sttaf-item1-con d-flex align-items-center justify-content-center">
								<h4 className="d-table-cell align-middle mb-0 text-center count">
									{parcentage}
								</h4>
								<span className="text-white static-txt2">%</span>
							</div>
						</div>
					</div>
				</div>
			</div>
			<div className="service-skill-sttaf-item-heading">
				<h3 className="mb-0 text-white">{title}</h3>
			</div>
		</div>
	)
}
