export default function EffectItem({ variant, datam }) {
	const { image, title, content } = datam

	return (
		<div
			className={`${
				variant === 4 ? 'col-lg-3 col-md-6 col-12' : 'col-lg-4 col-md-4 col-12'
			} `}
		>
			<div className="Effective-sec-item mb-lg-0 mb-4">
				<div className={variant === 4 ? '' : 'Effective-sec-item-img'}>
					<figure>
						<img src={image} alt={title} className="img-fluid" />
					</figure>
				</div>
				<div className="Effective-sec-item-title">
					<h4 className="mb-0">{title}</h4>
					{content.map(con => (
						<p className="mb-0" key={Math.random()}>
							{con}
						</p>
					))}
				</div>
			</div>
		</div>
	)
}
