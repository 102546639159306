import CyberSecurity from '../sections/CyberSecurity'
import Footer from '../sections/Footer'
import GetInTouch from '../sections/GetInTouch'
import GetStart from '../sections/GetStart'
import Hero from '../sections/Hero'
import Partners from '../sections/Partners'
import Services from '../sections/Services'
import WeAre from '../sections/WeAre'
import WeDo from '../sections/WeDo'
import Banner from '../sections/Banner'
import { useEffect } from 'react'

const weAreDatam = [
	'Full stack offensive application testing.',
	'Let us attempt Social Engineering on your human assets.',
	'Our team of trained professional can attempt to access your physical assets.',
	'Automated real time scanning of your entire network.',
]

export default function Home() {
	useEffect(() => {
		document.title = 'WSSC | Web Security Solutions Co | Home'
		return function () {
			window.scrollTo(0, 0)
		}
	}, [])

	return (
		<>
			<Hero>
				<Banner />
			</Hero>
			<Partners />
			<WeAre
				datam={weAreDatam}
				title="Experts in Red Team penetration testing"
				content="Our Red Team penetration testing unit will identify then exploit&nbsp; security vulnerabilities found within your organization. This is achieved via a systematic testing framework focusing on your companies applications, physical facilities, networks and human assets."
			/>
			<WeDo />
			<Services />
			<CyberSecurity />
			<GetStart />
			<GetInTouch />
			<Footer />
		</>
	)
}
