export default function BannerContact() {
	return (
		<section className="banner-main-con about-page-main-banner-con">
			<div className="container">
				<div className="banner-con about-page-banner-con text-center">
					<div className="row wow slideInLeft">
						<div className="col-lg-12">
							<div className="about-page-banner-title">
								<h1>Contact Us</h1>
								<p className="mb-0">
									Security is essential to the operation of your
									business.
									<br />
									Contact us any time for we take pride in our near
									instant communication.
								</p>
							</div>
						</div>
					</div>
				</div>
			</div>
		</section>
	)
}
