import CircleQuality from "../CircleQuality";

const datam = [
  { progress: "100", title: "Quality Services" },
  { progress: "100", title: "Skilled Staff" },
  { progress: "100", title: "Support Team" },
];

export default function Services() {
  return (
    <div className="service-main-con">
      <div className="row ml-0 mr-0">
        <div className="col-lg-6 pl-0 pr-0">
          <div className="service-main-left-top-con dots-right-img">
            <div className="row">
              <div className="col-xl-7 col-lg-12 ml-auto">
                <div className="service-main-left-con wow slideInLeft">
                  <h5>Reliable Cyber Security</h5>
                  <h2>Offensive Security Services By Top Security Experts</h2>
                  <p>
                    Red team penetration testing is a full-scope, multi-layered
                    attack simulation used by a firm to assess how its security
                    system can combat real-life attack.
                  </p>
                  <p>
                    Red team penetration testing investigates the entire
                    security of an organization including technology systems
                    such as routers, networks, switches, applications, and other
                    devices. It also covers staff, business partners,
                    contractors, and departments and physical properties such as
                    data centers, warehouse, offices, substations, and other
                    rooms or buildings.
                  </p>
                  <div className="row service-skill-sttaf-con">
                    <CircleQuality
                      variant="firstPercentage"
                      parcentage={100}
                      title="Quality Services"
                    />
                    <CircleQuality
                      variant="secondPercentage"
                      parcentage={100}
                      title="Skilled Staff"
                    />
                    <CircleQuality
                      variant="thirdPercentage"
                      parcentage={100}
                      title="Support Team"
                    />
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
        <br />.
        <div className="service-box-content wow slideInRight">
          <figure>
            <img
              src="assets/image/service-box-img.png"
              alt="service-box-img"
              className="img-fluid"
            />
          </figure>
          <h6>Creative Security Solutions</h6>
          <p>
            Our team of industry experts will identify and patch any existing
            exploit on your system.
          </p>
          <a href="about.html">
            Read more <i className="fas fa-chevron-right"></i>
          </a>
        </div>
      </div>
    </div>
  );
}
