const datam = [
	{
		id: 938923,
		title: 'Extremely low response time at all time ',
		content:
			"Security is critical to your business we pride our self's on our near instant response time.",
	},
	{
		id: 232094,
		title: 'We are always ready for your growth',
		content:
			'No client is to large or to small. We proudly offer discounts to non profit and small business.',
	},
	{
		id: 549556,
		title: 'We understand security and compliance ',
		content:
			'Our expert team is always growing, we offer our staff a revolving door of education to ensure they are always up to date on the latest threats.',
	},
]

export default function BlogPost() {
	return (
		<div className="blog-post-details-con">
			<div className="row">
				{datam.map(data => (
					<div
						className="col-lg-4 col-md-4 col-sm-4 col-12 mb-lg-0 mb-md-0 mb-sm-0  mb-4 wow fadeInUp"
						key={data.id}
					>
						<div className="blog-post-details-sec-img float-left">
							<img
								src="assets/image/check-box-icon.png"
								alt="check-box-icon"
								className="img-fluid"
							/>
						</div>
						<div className="blog-post-details-heading-sec">
							<div className="blog-post-details-sec-title">
								<h4>{data.title}</h4>
							</div>
							<div className="blog-post-details-sec-content">
								<p className="mb-0">{data.content}</p>
							</div>
						</div>
					</div>
				))}
			</div>
		</div>
	)
}
