import Button from '../Button'

export default function Banner() {
	return (
		<section className="banner-main-con">
			<div className="container">
				<div className="banner-con">
					<div className="row">
						<div className="col-lg-7 col-md-7 col-sm-12 d-flex justify-content-center flex-column banner-main-left-con">
							<div className="banner-left-con wow slideInLeft">
								<div className="banner-heading">
									<h1>
										We can be your partner in reducing Cyber
										Security risk.
									</h1>
								</div>
								<div className="banner-content">
									<p className="col-lg-11 pl-0 pr-0">
										Offensive Security Experts !
									</p>
								</div>
								<div className="banner-btn">
									<Button variant="contact-btn" url="/about">
										Get started
									</Button>
									<Button
										variant="contact-btn contact-banner-btn"
										url="/contact"
									>
										Contact us
									</Button>
								</div>
							</div>
						</div>
						<div className="col-lg-5 col-md-5 col-sm-12">
							<div className="banner-right-con wow slideInRight">
								<figure className="mb-0">
									<img
										src="assets/image/banner-right-img.png"
										alt="banner-right-img"
									/>
								</figure>
							</div>
						</div>
					</div>
				</div>
			</div>
		</section>
	)
}
