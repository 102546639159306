import RectBox from '../RectBox'
import Button from '../Button'

export default function CyberSecurityAbout() {
	return (
		<section>
			<div className="Cyber-Security-con about-page-Cyber-main-con bg-overly-img w-100">
				<div className="container overlay-content">
					<div className="row wow fadeInUp">
						<div className="col-lg-12 col-md-12 Cyber-Security-left-con">
							<div className="row Cyber-Security-left-main-con mb-0">
								<RectBox
									image="assets/image/Cyber-Security-left-card-img1.png"
									title="Global Projects"
									number={3329}
									variant="SyberBox"
								/>
								<RectBox
									image="assets/image/Cyber-Security-left-card-img2.png"
									title="Clients Protected"
									number={4587}
									variant="SyberBox"
								/>
								<RectBox
									image="assets/image/Cyber-Security-left-card-img3.png"
									title="Service Guarantee"
									staticText={100}
									variant="SyberBox"
								/>
								<RectBox
									image="assets/image/Cyber-Security-left-card-img3.png"
									title="Expert Team Members"
									membars={29}
									variant="SyberBox"
								/>
								<div className="about-page-Cyber-Security-link ml-auto mr-auto">
									<Button variant="contact-btn" url="#" noMargin>
										Get started
									</Button>
								</div>
							</div>
						</div>
					</div>
				</div>
			</div>
		</section>
	)
}
