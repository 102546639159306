import Button from '../Button'

export default function GetStart() {
	return (
		<section>
			<div className="get-start-con bg-overly-img">
				<div className="container overlay-content">
					<div className="get-start-title text-center">
						<h1>Ready To Get Started? We're Here To Help.</h1>
						<div className="get-start-link text-center">
							<Button variant="contact-btn" url="/contact" noMargin>
								Let’s Talk
							</Button>
						</div>
					</div>
				</div>
			</div>
		</section>
	)
}
