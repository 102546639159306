export default function BrandWeight() {
	return (
		<div className="col-lg-4 col-md-12 col-12 text-lg-left text-md-center">
			<div className="weight-footer-item weight-footer-item1 mb-lg-0 mb-3">
				<div className="weight-footer-item-img">
					<figure>
						<img
							src="assets/image/logo-img.png"
							alt="logo-img"
							className="img-fluid"
						/>
					</figure>
				</div>
				<div className="weight-footer-item-content">
					<p className="mb-xl-0 mb-lg-0 mb-md-4 weight-footer-item1-content">
						Be perfectly Secure with us. <br />
						Our commitment is keeping you safe. <br />
						❤️💻
					</p>
				</div>
			</div>
		</div>
	)
}
