import Button from '../Button'

const datam = [
	{
		id: 859485984,
		image: 'assets/image/Effective-sec-item-img1.png',
		title: 'Offensive Security',
		describe: 'Allow our Red Team experts to audit your application and network.',
	},
	{
		id: 235873240958,
		image: 'assets/image/Effective-sec-item-img2.png',
		title: 'Defensive Security',
		describe: 'Our Blue Team will patch and secure your network and applications.',
	},
	{
		id: 450948545,
		image: 'assets/image/Effective-sec-item-img3.png',
		title: 'Physical Security',
		describe:
			'We employ some of the best in physical security allow our team to sneak right past your reception and into your server room.',
	},
	{
		id: 2938409384,
		image: 'assets/image/Effective-sec-item-img4.png',
		title: 'Social Engineering',
		describe:
			'How well can your staff recognize a social Engineering attempt? We are industry leaders in training your staff to not fall victim to this attack.',
	},
]

export default function WeDoInfo() {
	return (
		<section>
			<div className="Effective-con bg-overly-img w-100">
				<div className="container overlay-content">
					<div className="Effective-sec-heading text-center">
						<h5>What we do</h5>
						<h2>Complete And Effective Protection For Your Enterprise</h2>
					</div>
					<div className="row wow fadeInUp">
						{datam.map(data => (
							<div className="col-lg-3 col-md-6 col-12" key={data.id}>
								<div className="Effective-sec-item mb-lg-0 mb-4">
									<figure>
										<img
											src={data.image}
											alt={data.title}
											className="img-fluid"
										/>
									</figure>
									<div className="Effective-sec-item-title">
										<h4 className="mb-0">{data.title}</h4>
										<p className="mb-0">{data.describe}</p>
									</div>
								</div>
							</div>
						))}
						<div className="Effective-link ml-auto mr-auto">
							<Button variant="contact-btn" url="/about" noMargin>
								Get started
							</Button>
						</div>
					</div>
				</div>
			</div>
		</section>
	)
}
