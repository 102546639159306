import Footer from '../sections/Footer'
import Hero from '../sections/Hero'
import WeAre from '../sections/WeAre'
import BannerAbout from '../sections/BannerAbout'
import EffectAbout from '../sections/EffectAbout'
import CyberSecurityAbout from '../sections/CyberSecurityAbout'
import BlogPost from '../sections/BlogPost'
import { useEffect } from 'react'

const weAreDatam = [
	'Full stack offensive application testing.',
	'Let us attempt Social Engineering on your human assets.',
	'Automated real time scanning of your entire network.',
	'Our team of trained professional can attempt to access your physical assets.',
]

export default function About() {
	useEffect(() => {
		document.title = 'Cyber Chip | About-us'
		return function () {
			window.scrollTo(0, 0)
		}
	}, [])

	return (
		<>
			<Hero>
				<BannerAbout />
				<EffectAbout />
			</Hero>
			<WeAre
				datam={weAreDatam}
				title="Experts in all aspects of penetration testing..."
				content="Our Red Team penetration testing unit will identify then exploit&nbsp; security vulnerabilities found within your organization. This is achieved via a systematic testing framework focusing on your companies applications, physical facilities, networks and human assets."
			/>
			<CyberSecurityAbout />
			<section>
				<div className="blog-post-con about-page-blog-post-con">
					<div className="container">
						<BlogPost />
						<Footer />
					</div>
				</div>
			</section>
		</>
	)
}
