export default function Map() {
	return (
		<div className="Map-main-con">
			<div className="Map-main-inner-con wow slideInRight">
				<iframe
					src="https://maps.google.com/maps?q=Level%201%2C%20121%20King%20Street%20Melbourne%2C%203000%2C%20Australia&amp;t=m&amp;z=20&amp;output=embed&amp;iwloc=near"
					title="Level 1, 121 King Street Melbourne, 3000, Australia"
					aria-label="Level 1, 121 King Street Melbourne, 3000, Australia"
					className="w-100 border-0 overflow-hidden m-0"
				></iframe>
			</div>
		</div>
	)
}
