import EffectiveItem from '../EffectiveItem'

const datam = [
	{
		id: 2394839,
		image: 'assets/image/Effective-sec-item-img1.png',
		title: 'Offensive Security',
		content: ['Allow our Red Team experts to audit your application and network.'],
	},
	{
		id: 894383,
		image: 'assets/image/Effective-sec-item-img1.png',
		title: 'Defensive Security',
		content: [
			'Our Blue Team will patch and secure your  network and applications.',
		],
	},
	{
		id: 5454545,
		image: 'assets/image/Effective-sec-item-img2.png',
		title: 'Physical Security',
		content: [
			'We employ some of the best in physical security allow our team to sneak right past your reception and into your server room.',
		],
	},
	{
		id: 8309893,
		image: 'assets/image/Effective-sec-item-img3.png',
		title: 'Social Engineering',
		content: [
			'How well can your staff recognize a social Engineering attempt? <br /> <br /> We are industry leaders in training your staff to not fall victim to this attack.',
		],
	},
]

export default function EffectAbout() {
	return (
		<section>
			<div className="Effective-con about-page-Effective-con w-100 pt-0">
				<div className="container">
					<div className="row wow fadeInUp">
						{datam.map(data => (
							<EffectiveItem variant={4} datam={data} key={data.id} />
						))}
					</div>
				</div>
			</div>
		</section>
	)
}
